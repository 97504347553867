<script setup>
  import { formData, rules, formatPhoneNumber, disabledDate } from './zindagi-popup'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch, toRef } from 'vue'
  import { ElMessage } from 'element-plus'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['prevStep', 'nextStep'])

  const props = defineProps({
    hasEnteredBefore: {
      type: Boolean
    }
  })

  const formBoxRef = ref(null)

  const data = reactive({
    multiple: false,
    loading: false
  })

  const fileData = reactive({
    fileType: 'image/png, image/jpeg, image/jpg',
    fileMaxSize: 2,
    selectedFiles: []
  })

  const installationRequestNumberRules = {
    installationRequestNumber: [
      { required: true, message: 'Please enter installation request number', trigger: 'blur' },
      { pattern: /^[a-zA-Z0-9]{16,20}$/, message: 'Please enter a 16 to 20 character Installation Request Number consisting of letters and numbers only', trigger: 'blur' }
    ],
    installationRequestNumberNull: []
  }

  // 监听手机号输入
  const phoneNoRef = toRef(formData, 'formatPhoneNo')
  watch(phoneNoRef, (newValue) => {
    formData.formatPhoneNo = formatPhoneNumber(newValue)
  })

  // 处理安装编号
  const addInstallationRequestNumber = (formBoxRef) => {
    rules.installationRequestNumber = installationRequestNumberRules.installationRequestNumber
    formBoxRef.validateField('installationRequestNumber', (error) => {
      console.log(error, 'error')
      if (error) {
        console.log('字段校验通过')
        rules.value.installationRequestNumber = installationRequestNumberRules.installationRequestNumberNull
        formData.installationRequestNumberList.push(formData.installationRequestNumber)
        formData.installationRequestNumber = ''
      } else {
        console.log('字段验证失败')
      }
    })
  }

  // 删除安装编号
  const deleteItem = (index) => {
    formData.installationRequestNumberList.splice(index, 1)
  }

  // 更新图片
  const invoiceUploadImg = (list) => {
    formData.invoicePictureList = list
  }

  // 更新图片
  const warrantyCardUploadImg = (list) => {
    formData.warrantyCardPictureList = list
  }

  const prevStep = () => {
    $emit('prevStep')
  }

  const validateIrn = () => {
    data.loading = true
    api.activity
      .validateIrn({
        installation_request_no: formData.installationRequestNumber
      })
      .then((result) => {
        console.log('validateIrn', result)
        data.loading = false
        if (result.code === 200) {
          $emit('nextStep')
        } else {
          ElMessage.error(`${result.data.message || result.message}`)
        }
      })
      .catch((err) => {
        data.loading = false
      })
  }

  // 处理下一步操作
  const handleNext = (formBoxRef, formData) => {
    console.log(formData)
    if (formData.installationRequestNumberList.length) {
      rules.value.installationRequestNumber = installationRequestNumberRules.installationRequestNumberNull
    }
    formBoxRef.validate((valid, fields) => {
      if (valid) {
        console.log('Validation successful')
        // 这里可以执行其他逻辑，如提交表单数据等
        if (formData.type === 'add') {
          return validateIrn()
        }
        $emit('nextStep')
      }
    })
  }
</script>

<template>
  <div class="rowCC">
    <div class="columnSS form-content-box" v-loading="data.loading">
      <div class="title mb10">Purchase details</div>
      <el-form ref="formBoxRef" class="form-box" label-position="top" label-width="auto" :model="formData" :rules="rules" hide-required-asterisk>
        <!-- <el-form-item label="AC Quantity" prop="ACQuantity">
          <el-input v-model="formData.ACQuantity" placeholder="Enter Quantity in Numbers" type="Number" @blur="formData.name = $event.target.value.trim()" clearable />
        </el-form-item> -->

        <el-form-item label="Purchase Date" prop="purchaseDate">
          <el-date-picker
            v-model="formData.purchaseDate"
            style="width: 100%"
            type="date"
            placeholder="Select Date"
            value-format="YYYY-MM-DD"
            :disabledDate="disabledDate"
            :clearable="false"
          />
        </el-form-item>

        <el-form-item label="Invoice Picture" prop="invoicePictureList">
          <div class="upload-box">
            <img-upload
              v-model="formData.invoicePictureList"
              :fileCount="formData.ACQuantity || 1"
              :imgs="formData.invoicePictureList"
              @imgsCallback="invoiceUploadImg"
              :fileMaxSize="fileData.fileMaxSize"
            />
          </div>
        </el-form-item>
        <el-form-item label="Warranty Card Picture" prop="warrantyCardPictureList">
          <div class="upload-box">
            <img-upload
              v-model="formData.warrantyCardPictureList"
              :fileCount="formData.ACQuantity || 1"
              :imgs="formData.warrantyCardPictureList"
              @imgsCallback="warrantyCardUploadImg"
              :fileMaxSize="fileData.fileMaxSize"
            />
          </div>
        </el-form-item>

        <template v-if="Number(formData.ACQuantity) > 1" data-remark="多个数量多个安装编号">
          <el-form-item label="Installation Request Number" prop="installationRequestNumber">
            <el-input
              v-model="formData.installationRequestNumber"
              minlength="16"
              maxlength="20"
              placeholder="Enter Installation Request Number"
              @blur="formData.installationRequestNumber = $event.target.value.trim()"
            >
              <template #suffix>
                <el-button class="btn1" @click="addInstallationRequestNumber(formBoxRef)">add</el-button>
              </template>
            </el-input>
          </el-form-item>
          <div data-remark="安装编号展示" class="installation-list-box" v-if="formData.installationRequestNumberList.length">
            <div v-for="(item, index) in formData.installationRequestNumberList" :key="index" class="installation-item rowCC">
              <div>{{ index + 1 }}. {{ item }}</div>
              <div class="close" @click="deleteItem(index)"></div>
            </div>
          </div>
        </template>
        <template v-else data-remark="1个数量">
          <el-form-item label="Installation Request Number" prop="installationRequestNumber">
            <el-input
              v-model="formData.installationRequestNumber"
              minlength="16"
              maxlength="20"
              placeholder="Enter Installation Request Number"
              @blur="formData.installationRequestNumber = $event.target.value.trim()"
            >
            </el-input>
          </el-form-item>
        </template>

        <el-form-item label="Installation Completion Date" prop="installationCompleteDate">
          <el-date-picker
            v-model="formData.installationCompleteDate"
            style="width: 100%"
            type="date"
            placeholder="Select Date"
            value-format="YYYY-MM-DD"
            :disabledDate="disabledDate"
            :clearable="false"
          />
        </el-form-item>
        <el-form-item>
          <div class="rowCC wh100 mt20">
            <el-button class="form-btn btn2" @click="prevStep">
              <div class="back-ico"></div>
              <p>{{ props.hasEnteredBefore ? 'Update Info' : 'Personal Info' }}</p>
            </el-button>
            <el-button type="primary" class="form-btn btn1" @click="handleNext(formBoxRef, formData)">Next</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @import '@/assets/css/element-custom.less';

  .form-content-box {
    width: 100%;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
    }
  }

  .form-box {
    width: 100%;
    .form-btn {
      min-width: 170px;
      height: 42px;
      border-radius: 24px;
    }

    .btn1 {
      background-color: var(--btnBgColor);
      color: var(--button-text-color);
    }

    .btn2 {
      background-color: var(--boxBgColor);
      color: var(--mainBgColor);
    }

    .back-ico {
      width: 20px;
      height: 20px;
      margin-right: 2px;
      background: url('https://cdn.qingmeidata.com/d0bceeee.png') no-repeat;
      background-size: 100% 100%;
    }

    .item-upload-box {
      .upload-ico {
        width: 20px;
        height: 20px;
        background: url('https://cdn.qingmeidata.com/355196a9.png') no-repeat;
        background-size: 100% 100%;
      }
      .inputer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
      }
    }

    .installation-list-box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px 10px; /* 行间距为10px，列间距为0px */
      justify-content: center; /* 列内元素居中对齐 */
      width: 100%;
      max-height: 80px;
      padding: 10px 10px;
      background: #fff;
      margin-bottom: 10px;
      box-shadow: 0px 4.44px 56.87px 26.66px rgba(136, 191, 255, 0.12);
      overflow: auto;
      overflow: auto;
      .installation-item {
        height: 30px;
        padding: 8px 6px;
        background: #fff;
        border: 1px solid rgba(68, 94, 124, 0.08);
      }
      .close {
        width: 10px;
        height: 10px;
        margin-left: 6px;
        background: url('https://cdn.qingmeidata.com/1cb0c53a.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
    /* 在小屏幕上，每行只显示一个元素 */
    @media screen and (max-width: 768px) {
      .installation-list-box {
        grid-template-columns: repeat(1, 1fr); /* 每行只有一列 */
      }
    }
  }
</style>
